import React from 'react';

export default function Footer() {
  return (
    <section className="bg-darkblue px-5 py-2">
        <div className="container mx-auto">
            <div className="flex items-center">
                <a href="https://mmda.gov.ph/" target="_blank" rel="noopener noreferrer"><img src="/images/mmfmp-footer-logo-mmda.png" alt="logo-mmda" className="h-auto max-w-full mr-3" style={{width: '50px'}}/></a>
                <img src="/images/mmfmp-footer-logo-mmfmp.png" alt="logo-mmfmp" className="h-auto max-w-full mr-3" style={{width: '50px'}}/>
                <img src="/images/mmfmp-logo.png" alt="mmfmp" className="h-auto max-w-full mr-3" style={{width: '50px'}}/>
                <p className="text-white text-sm">Copyright 2024</p>
            </div>
        </div>
    </section>
  )
}
