import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import {PageBanner, PDFListing } from '../CommonComponents.js';

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from '../config';
import { queries } from '../GraphQL/Queries';

//CALL THIS EVERY TIME NEED TO USE THE QUERY
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const pagebanner = [
  {
    bannersrc: '/images/whatwedo/mmfmp-whatwedo-banner.jpg',
    header: 'pROGRAMS AND PROJECTS',
    copy: 'An overview of the different initiatives, programs, and projects under the MMFMP with a focus on Component 2, which is to minimize solid waste in waterways.'
  }
]

function RenderProgramsList({contentlist, type}){
    let textcolor, boxcolor;

    if (type === 'programs'){
        textcolor = "text-white"
        boxcolor = "bg-darkblue"
    }
    else {
        textcolor = "text-blue"
        boxcolor = "bg-zinc-200"
    }

    return(
        contentlist.map(content => {
            return(
                <Link to={content.slug} key={content.title}>
                    <div className="lg:flex mb-10">
                        <div className="relative lg:basis-1/2">
                            <img src={content.featuredImage.node.mediaItemUrl} alt={content.title} className="object-cover max-lg:rounded-t-2xl lg:rounded-tl-2xl lg:rounded-bl-2xl"/>
                            <div className="absolute bottom-0 bg-black bg-opacity-50 p-5 w-full lg:rounded-bl-2xl">
                                <h1 className="text-white bakbak-one-regular text-2xl" dangerouslySetInnerHTML={{__html: content.title}}/>
                            </div>
                        </div>
                        <div className={`${boxcolor} lg:basis-1/2 flex flex-col justify-center px-10 py-8 lg:py-0 max-lg:rounded-b-2xl lg:rounded-tr-2xl lg:rounded-br-2xl`}>
                            <p className={`${textcolor} barlow-regular text-base`} dangerouslySetInnerHTML={{__html: content.blurb}} />
                            <p className={`${textcolor} barlow-regular text-base underline mt-8`}>Learn more</p>
                        </div>
                    </div> 
                </Link> 
            )
        })
    )
}

function ProgramsList({title, contentdata, type}){
    return(
        <section className="px-5 py-10">
            <div className="container mx-auto">
                <h1 className="text-blue bakbak-one-regular text-3xl uppercase" dangerouslySetInnerHTML={{__html: title}}/>
                <div className="px-5 md:px-10 pt-5">
                    <RenderProgramsList contentlist={contentdata} type={type}/>
                </div>
            </div>
        </section>
    )
}

function ProjectsList({title, subcategory, type}){
    return(
        <section className="px-5 py-10">
            <div className="container mx-auto">
                <h1 className="text-blue bakbak-one-regular text-3xl uppercase" dangerouslySetInnerHTML={{__html: title}}/>
                {
                    subcategory.map(subcat => {
                        return (
                            <div className="pt-5" key={subcat.node.name}>
                                <h1 className="text-blue bakbak-one-regular text-lg uppercase mb-5" dangerouslySetInnerHTML={{__html: subcat.node.name}}/>
                                <div className="px-5 md:px-10">
                                    <RenderProgramsList contentlist={subcat.node.programs.nodes} type={type}/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

function GetData(){
    const { data, isLoading, error } = useQuery("programCategories", () => {
      return request(mmfmpConfig.apiUrl, queries.programsData);
    });
  
    if (isLoading) return "Loading...";
    if (error) return <pre>{error.message}</pre>;

    return(
        data.programCategories.nodes.map(nodes =>{           
            return (
                <Fragment key={nodes.name}>
                    {
                        nodes.children.edges.length > 0
                        ?
                        <ProjectsList title={nodes.name} subcategory={nodes.children.edges} />
                        :
                        <ProgramsList title={nodes.name} type="programs" contentdata={nodes.programs.nodes}/>
                    }
                </Fragment>
            )
        })
    )
}

function ActionPlan() {
  return (
    <section className="px-5 py-10 bg-blueband">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12">
          <div className="text-white lg:col-span-11">
            <h1 className="uppercase bakbak-one-regular text-4xl mb-10">ACTION PLAN</h1>
            <p className="barlow-regular text-base mb-5">Focusing on these specific 270 upstream barangays allows for better resource allocation and efficiency, as these areas are most at risk of flooding and contribute a lot of waste to waterways. Through this,  the development of a model waste management system that can be improved and used in other parts of Metro Manila is possible. This system will include innovative SWM facilities and practices, and improved delivery of SWM services to the local communities.</p>
            <p className="barlow-regular text-base mb-5">The project will work on reducing waste in waterways and pumping stations by improving sorting and collection services, providing tools and equipment, setting up mobile recovery systems, and improving collection in hard-to-reach areas. It will also invest in new technologies like composting, waste conversion (e.g., processing water hyacinth, brick making), and possibly waste-to-energy facilities.</p>
            <p className="barlow-regular text-base mb-2">In addition to these focused interventions, the project will also address waste management needs across Metro Manila. This includes supporting barangays and local governments, influencing their planning and decision-making, and educating the public to ensure these practices are adopted and sustained.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

const aboutourwork = [
    {
        "hideDate": true,
        "resourceFile": "https://mmfmpcms.mmda.gov.ph/wp-content/uploads/2024/07/MMRF-BRIEFER.pdf",
        "title": "MMRF Briefer"
    },
     {
        "hideDate": true,
        "resourceFile": "https://mmfmpcms.mmda.gov.ph/wp-content/uploads/2024/07/MMFMP-Project-Intro-Flyer.pdf",
        "title": "A Brief Overview of the MMFMP Project"
    }
]

function AboutOurWork() {
  return (
    <section className="px-5 pt-10 pb-5" style={{backgroundColor: '#7bcbc8'}}>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12">
          <div className="text-blue lg:col-span-11">
            <h1 className="uppercase bakbak-one-regular text-4xl">ABOUT OUR WORK</h1>
            <PDFListing pdffiles={aboutourwork} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default function WhatWeDoLanding(){
  return (
    <>
      <section id="banner"><PageBanner pagebanner={pagebanner}/></section>
      <QueryClientProvider client={queryClient}>
        <GetData />
        <ActionPlan/>
        <AboutOurWork/>
      </QueryClientProvider>
    </>
  )
}