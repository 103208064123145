import React, { Fragment } from 'react';
import Slider from "react-slick";

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from '../config.js';
import { queries } from '../GraphQL/Queries.js';

export default function SWMISSection() {
  const { data, isLoading, error } = useQuery("swmisposts", () => {
      return request(mmfmpConfig.apiUrl, queries.swmisData);
  });

  if (isLoading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;

  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
  };
console.log( data.swmisposts.nodes)
  return (
    <section className="bg-blue px-5 pt-5 pb-10">
      <div className="container mx-auto">
        <h1 className="barlow-bold text-white text-2xl">METRO MANILA SOLID WASTE MANAGEMENT INFORMATION SYSTEM</h1>
          <p className="text-white barlow-regular">An overview of the data and analytics of the different solid waste management programs under Component 2 of the MMFMP.</p>
        <div className="px-8 lg:px-10 mb-10 mt-8">
          <Slider {...settings}>
          {
            data.swmisposts.nodes.map(img => {
              return (
                <Fragment key={img.featuredImage.node.id}>
                <img  src={img.featuredImage.node.sourceUrl} alt="" className="mx-auto px-5 lg:px-20"/>
                <p className="text-white barlow-regular text-sm px-10 mt-5" dangerouslySetInnerHTML={{__html: img.content}} />
                </Fragment>
              )
            })
          }
          </Slider>
        </div>
        <a href="https://swmis.mmda.gov.ph/" target="_blank" rel="noopener noreferrer" className="barlow-bold text-white rounded-3xl border border-white px-8 py-2">Learn more</a>
      </div>
    </section>
  );
}