import React from 'react';
import {PageBanner} from '././CommonComponents';

const pagebanner = [
  {
    bannersrc: '/images/contact/mmfmp-contactus-banner.jpg',
    header: 'Contact Us',
    copy: ''
  }
]

const govlinks = [
    {
        "link": "https://mmda.gov.ph/",
        "title": "MMDA"
    },
    {
        "link": "http://president.gov.ph/",
        "title": "Office of the President"
    },
    {
        "link": "http://ovp.gov.ph/",
        "title": "Office of the Vice President"
    },
    {
        "link": "http://www.senate.gov.ph/",
        "title": "Senate of the Philippines"
    },
    {
        "link": "http://www.congress.gov.ph/",
        "title": "House of Representatives"
    },
    {
        "link": "http://sc.judiciary.gov.ph/",
        "title": "Supreme Court"
    },
    {
        "link": "http://ca.judiciary.gov.ph/",
        "title": "Court of Appeals"
    },
    {
        "link": "http://sb.judiciary.gov.ph/",
        "title": "Sandiganbayan"
    }
]

export default function ContactUs() {
  return (
    <>
    <PageBanner pagebanner={pagebanner}/>
    <section className=" px-5 py-5">
        <div className="container mx-auto">

            <div className="grid lg:grid-cols-12 lg:gap-10 px-5 pt-5 pb-10">
                <div className="lg:col-span-4 lg:col-start-2 text-blue flex items-center text-lg">
                    <div>
                        <p className="barlow-bold mb-3">VISIT US</p>
                        <p className="barlow-regular mb-12">Doña Julia Vargas Avenue, <br className="hidden lg:block"/>corner Molave Street, Pasig City</p>
                    </div>
                </div>
                <div className="grid lg:col-span-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15445.077513350505!2d121.0670007!3d14.5837203!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c97a2d4c4309%3A0x7eb60b16f3d01517!2sNew%20MMDA%20Head%20Office!5e0!3m2!1sen!2sph!4v1714727704084!5m2!1sen!2sph" width="600" height="250" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="address" className="max-w-full px-2"></iframe>
                </div>
            </div>

             <div className="grid md:grid-cols-2 lg:grid-cols-4 px-5 md:px-12 pt-5 pb-10 text-blue md:text-center">
                <div className=" mb-10">
                    <a href="mailto: swmo@mmda.gov.ph">
                        <img src="/images/contact/mmfmp-contactus-icon-email.png" alt="email" className="w-1/6 md:mx-auto" />
                        <p className="barlow-regular my-2">swmo@mmda.gov.ph</p>
                    </a>
                </div>
                <div className=" mb-10">
                    <a href="tel:+0288984200">
                        <img src="/images/contact/mmfmp-contactus-icon-call.png" alt="call" className="w-1/6 md:mx-auto" />
                        <p className="barlow-regular my-2">(02) 8898-4200<br/>Local numbers 1308-1311</p>
                    </a>
                </div>
                <div className=" mb-10">
                    <a href="https://web.facebook.com/MMDAmmfmp" target="_blank" rel="noopener noreferrer">
                        <img src="/images/contact/mmfmp-contactus-icon-fb.png" alt="fb" className="w-1/6 md:mx-auto" />
                        <p className="barlow-regular my-2">Metro Manila Flood<br/>Management Project</p>
                    </a>
                </div>
                <div className=" mb-10">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSc6pWky9mtrcHztjORCdCx99MgFm0T8XnnnxJxxpakLrB_3qw/viewform" target="_blank" rel="noopener noreferrer">
                        <img src="/images/contact/mmfmp-contactus-icon-form.png" alt="form" className="w-1/6 md:mx-auto" />
                        <p className="barlow-regular my-2">Grievance Form</p>
                    </a>
                </div>
            </div>

            <div className="grid lg:grid-cols-12 gap-10 px-5 pb-10">
                <div className="grid lg:col-span-5 lg:col-start-2 text-blue text-lg">
                    <p className="barlow-bold mb-5">GOVERNMENT LINKS</p>
                    {
                        govlinks.map(govlink => {
                            return <a key={govlink.title} className="hover:underline sm:w-2/3" href={govlink.link} target="_blank" rel="noopener noreferrer"><p className="barlow-regular mb-2" dangerouslySetInnerHTML={{__html: govlink.title}}/></a>
                        })
                    }                 
                </div>
                <div className="grid lg:col-span-5 max-lg:mt-5">
                    <div className="md:flex items-center">
                        <a href="https://mmda.gov.ph/" target="_blank" rel="noopener noreferrer"><img src="/images/contact/mmfmp-contactus-logo-01.png" alt="logo-mmda" className="h-auto max-w-full mb-5 px-5 w-1/2 md:max-lg:w-3/5 lg:w-full"/> </a>     
                        <a href="https://www.dpwh.gov.ph/dpwh/" target="_blank" rel="noopener noreferrer"><img src="/images/contact/mmfmp-contactus-logo-02.png" alt="logo-mmda" className="h-auto max-w-full mb-5 px-5 w-1/2 md:max-lg:w-3/5 lg:w-full"/></a>             
                        <div><img src="/images/contact/mmfmp-contactus-logo-04.png" alt="logo-mmda" className="h-auto max-w-full mb-5 px-5 w-1/2 md:max-lg:w-3/5 lg:w-full"/></div>
                    </div>
                    <div className="md:flex items-center">
                        <div><img src="/images/contact/mmfmp-contactus-logo-03.png" alt="logo-mmda" className="h-auto max-w-full mb-5 px-5 w-1/2 md:max-lg:w-3/5 lg:w-full"/></div>
                        <a href="https://ida.worldbank.org/en/home" target="_blank" rel="noopener noreferrer"><img src="/images/contact/mmfmp-contactus-logo-05.png" alt="logo-mmda" className="h-auto max-w-full mb-5 px-5 w-1/2 md:max-lg:w-3/5 lg:w-full"/></a>
                        <a href="https://www.aiib.org/en/index.html" target="_blank" rel="noopener noreferrer"><img src="/images/contact/mmfmp-contactus-logo-06.png" alt="logo-mmda" className="h-auto max-w-full mb-5 px-5 w-1/2 md:max-lg:w-3/5 lg:w-full"/></a>
        
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
