import React from 'react';
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import { Link } from "react-router-dom";

const reports = [
  {
    "title": "Look Into Our Annual Reports",    
    "link": "/resources/semi-annual-and-annual-reports"
  },
  {
    "title": "Get Relevant Case Studies",    
    "link": "/resources/studies"
  },
  {
    "title": "Other Downloads",    
    "link": "/resources/other-downloads"
  }
]

export default function ReportsSection() {
  return(
    <section className="bg-lightblue px-5 py-10">
      <div className="container mx-auto">
        <h1 className="text-white text-2xl barlow-bold uppercase">RESOURCES</h1>
        {/* <p className="text-blue barlow-regular">Stay informed with our informative blog posts</p> */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mt-8" >
          {
            reports.map(report =>{
              return (
                <Link to={report.link} key={report.title}>
                  <div className="bg-white p-5 rounded-3xl flex flex-col">
                    <span className="barlow-bold text-xl text-blue mb-5" dangerouslySetInnerHTML={{__html: report.title}} />
                    <ArrowRightCircleIcon className="block h-6 w-6 text-blue" aria-hidden="true" />
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}
