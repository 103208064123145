import React, { Component, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { PlayIcon } from '@heroicons/react/20/solid';
import { Input } from '@headlessui/react'
import { Link, useNavigate} from 'react-router-dom';
import Pagination from "react-js-pagination";

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from './config';
import { queries } from './GraphQL/Queries';

//CALL THIS EVERY TIME NEED TO USE THE QUERY
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

function SearchResultListing({resultlist, resulttype}) {
  return (
    <section className="px-5 md:px-10">
        <div className="container mx-auto text-blue">
        {
            resultlist.map(result => {
                if (resulttype === "resources"){
                    return (
                        <div className="pb-5" key={result.title}>
                            <div className="px-5">
                                <a href={result.resourceFile} target="_blank" rel="noopener noreferrer">
                                    <h1 className="barlow-bold text-lg" dangerouslySetInnerHTML={{__html: result.title}}/>
                                    <p className="barlow-regular-italic text-base my-2" dangerouslySetInnerHTML={{__html: result.resourceCategories.nodes[0].name}}/>
                                </a>
                            </div>
                            <hr className="border border-gray-500 mt-5"/>
                        </div>
                    )
                }
                else if (resulttype === "media"){
                    return (
                        <div className="pb-5" key={result.title}>
                            <div className="px-5">
                                <Link to={'/news/' + result.slug} >
                                    <h1 className="barlow-bold text-lg" dangerouslySetInnerHTML={{__html: result.title}}/>
                                    <p className="barlow-regular text-base my-2" dangerouslySetInnerHTML={{__html: result.blurb}}/>
                                    {
                                        result.mediaCategories.nodes.map((category, index) =>{
                                            return(
                                               <p key={index} className="barlow-regular-italic text-base" dangerouslySetInnerHTML={{__html: category.name}}/> 
                                            )
                                        })
                                    }
                                </Link>
                            </div>
                            <hr className="border border-gray-500 mt-5"/>
                        </div>
                    )
                }
                 else if (resulttype === "programs"){
                    // Check if parent exists
                    const categoryNode = result.programCategories.nodes[0];
                    const categoryName = categoryNode.parent?.node ? categoryNode.parent.node.name : categoryNode.name;

                    return (
                        <div className="pb-5" key={result.title}>
                            <div className="px-5">
                                <Link to={'/what-we-do/' + result.slug} >
                                    <h1 className="barlow-bold text-lg" dangerouslySetInnerHTML={{__html: result.title}}/>
                                    <p className="barlow-regular text-base my-2" dangerouslySetInnerHTML={{__html: result.blurb}}/>
                                    <p className="barlow-regular-italic text-base" dangerouslySetInnerHTML={{__html: categoryName}}/> 
                                </Link>
                            </div>
                            <hr className="border border-gray-500 mt-5"/>
                        </div>
                    )
                }
                else return (
                    <div className="pb-5" key={result.title}>
                        <div className="px-5">
                            <Link to={result.slug} >
                                <h1 className="barlow-bold text-lg" dangerouslySetInnerHTML={{__html: result.title}}/>
                                {
                                    result.content !== undefined && result.content !== ""
                                    ?
                                    <p className="barlow-regular text-base" dangerouslySetInnerHTML={{__html: result.content}}/>
                                    :
                                    ""
                                }
                            </Link>
                        </div>
                        <hr className="border border-gray-500 mt-5"/>
                    </div>
                )
            })
        }
        </div>
    </section>
  )
}

class ResultsPagination extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentPage: 1,
      resultsPerPage: 4
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidUpdate (nextProps) {
    if (nextProps.resultlist !== this.props.resultlist){
      this.setState({currentPage: 1});
    }
  }

  handlePageChange(pageNumber) {
    this.setState({currentPage: pageNumber});
  }

  render() {
    let { currentPage, resultsPerPage } = this.state;

    let { resultlist, resulttype } = this.props;

    const lastPage = currentPage * resultsPerPage;
    const firstPage = lastPage - resultsPerPage;
    const currentResults = resultlist.slice(firstPage, lastPage); 

    return (
      <section className="px-5 py-8">
        <SearchResultListing resultlist={currentResults} resulttype={resulttype}/>
        <div className="container mx-auto py-10 flex justify-center news-pagination">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={4}
            totalItemsCount={resultlist.length}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
            hideFirstLastPages={true}
            prevPageText={<PlayIcon className="rotate-180 block h-6 w-6 " />}
            nextPageText={<PlayIcon className="block h-6 w-6 "/>}
            activeClass="bg-blue text-white"
            activeLinkClass="text-white"
            itemClass="barlow-regular bg-gray-200 text-gray-500 text-lg mx-1 px-4 py-1"
            innerClass="flex"
          /> 
        </div>
      </section>
    );
  }
}

class SearchResults extends Component {
    constructor(props){
        super(props);
        this.state = {
        //   sitesearchresults: props.searchdata,
        resourcessearchresults: props.resourcesresults,
        mediasearchresults: props.mediaresults,
        programssearchresults: props.programsresults,
        };
    }

    render(){
        let { resourcessearchresults, mediasearchresults, programssearchresults } = this.state;

        return (
            <>
                {
                    programssearchresults.length > 0
                    ?
                    <>
                        <div className="container mx-auto p-3 bg-blueband">
                            <h1 className="barlow-bold text-2xl text-white">Programs and Projects</h1>
                        </div>
                        <ResultsPagination resultlist={programssearchresults} resulttype="programs"/>
                    </>
                    : null
                }
                {
                    mediasearchresults.length > 0
                    ?
                    <>
                        <div className="container mx-auto p-3 bg-blueband">
                            <h1 className="barlow-bold text-2xl text-white">News & Updates</h1>
                        </div>
                        <ResultsPagination resultlist={mediasearchresults} resulttype="media"/>
                    </>
                    : null
                }
                {
                    resourcessearchresults.length > 0
                    ?
                    <>
                        <div className="container mx-auto p-3 bg-blueband">
                            <h1 className="barlow-bold text-2xl text-white">Resources</h1>
                        </div>
                        <ResultsPagination resultlist={resourcessearchresults} resulttype="resources"/>
                    </>
                    : null
                }
                {/* {
                    sitesearchresults.length > 0
                    ?
                    <>
                        <div className="container mx-auto p-3 bg-blueband">
                            <h1 className="barlow-bold text-2xl text-white">Pages</h1>
                        </div>
                        <ResultsPagination resultlist={sitesearchresults} resulttype="media"/>
                    </>
                    : null
                } */}
                
            </>
        )
    }
}

function RenderSearchBar({resultcopy, searchstr}){
    const [inputValue, setInputValue] = useState(searchstr || '');
    const history = useNavigate();

    const handleSearchChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const handleSearchSubmit = () => {
        if (inputValue) {
            // Update the URL params with the new search key
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('key', inputValue);
            history({ search: urlParams.toString() });

            // Reload the page
            window.location.reload();
        }
    };
    return (
        <section className="bg-lightgrey px-5 pb-12 pt-40">
            <div className="container mx-auto relative">
                <div className="grid lg:grid-cols-9">
                    <div className="lg:col-span-5 lg:col-start-3">
                        <h1 className="barlow-bold text-2xl text-blue mb-5">Search</h1>
                        <div className="flex flex-shrink-0 items-center">
                            <Input 
                                name="search" 
                                type="text" 
                                className="barlow-regular block rounded-3xl px-3 py-3 bg-white w-full text-lg text-blue" 
                                placeholder="What are you looking for?"
                                value={inputValue}
                                onChange={handleSearchChange}
                                onKeyDown={handleKeyPress}
                            />
                            <MagnifyingGlassIcon onClick={handleSearchSubmit} className="absolute w-6 right-5 lg:right-1/4 cursor-pointer" />
                        </div>
                    </div>
                </div>
                <div className="grid lg:grid-cols-9 mt-10">
                    <div className="lg:col-span-5 lg:col-start-3">
                    {
                        resultcopy !== "" && resultcopy !== null && resultcopy !== undefined
                        ?
                        <h1 className="barlow-bold text-xl text-blue mb-5 uppercase">{resultcopy} "{searchstr}"</h1>
                        :
                        ""
                    }
                    </div>
                </div>
            </div>        
        </section>
    )
}

function GetSearchData(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchstr = urlParams.get('key');

    const variables = {
        search: searchstr, //SEARCH STRING
    }

    const {data, isLoading} = useQuery({
        queryFn: () => request(mmfmpConfig.apiUrl, queries.searchData, variables),
        queryKey: ["searchData", searchstr], // Include searchstr in the query key for uniqueness
        enabled: !!searchstr, // Disable query if searchstr is null or empty
        staleTime: Infinity,
        cacheTime: 0,
    });

        
    if (!searchstr){
        return(
            <RenderSearchBar />
        )
    } else {
        if (isLoading){
            return (
                <>
                    <RenderSearchBar resultcopy="Searching pages containing" searchstr={searchstr}/>
                </>
            )
        } else {
            if (data.resources.nodes.length > 0 || data.newsposts.nodes.length > 0 || data.programs.nodes.length > 0){
            return (
                <>
                    <RenderSearchBar resultcopy="Search results for" searchstr={searchstr}/>
                    <SearchResults resourcesresults={data.resources.nodes} mediaresults={data.newsposts.nodes} programsresults={data.programs.nodes} />
                </>
            )
            }
            else return <RenderSearchBar resultcopy="No search results for" searchstr={searchstr}/>
        }
    }
}

export default function SearchLanding (){
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <GetSearchData />
            </QueryClientProvider>
        </>
    );
}
