import React from 'react';
import VideoBanner from './VideoBanner.js';
import ReportsSection from './ReportsSection.js'
// import SWMISGallery from './SWMISGallery.js'
import NewsSection from './NewsSection.js'

//CALL THIS EVERY TIME NEED TO USE THE QUERY
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();

export default function Homepage() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <VideoBanner/>
        {/* <SWMISGallery/> */}
        <NewsSection />
      </QueryClientProvider>
      <ReportsSection/>
    </>
  )
}
