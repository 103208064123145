import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import { useState } from "react";
import Error404 from '../Error404';

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from '../config';
import { queries } from '../GraphQL/Queries';

//CALL THIS EVERY TIME NEED TO USE THE QUERY
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function BannerSection({banner, header, subcategory, title }) {
  return (
    <section style={{backgroundImage: 'url(' + banner + ')'}} className="bg-no-repeat bg-cover pt-24 pb-16 h-full max-lg:pb-10">
      <div className="container mx-auto px-3 py-24">
        <div className="lg:w-1/2 xl:w-2/5">
          <p dangerouslySetInnerHTML={{__html: header}} className="text-white bakbak-one-regular uppercase text-2xl"/>
          <p dangerouslySetInnerHTML={{__html: subcategory}} className="text-white bakbak-one-regular uppercase text-base mt-1 mb-5"/>
          <h1 dangerouslySetInnerHTML={{__html: title}} className="text-white bakbak-one-regular uppercase text-4xl"/>
        </div>
      </div>
    </section>
  )
}

function RenderProgramsContent({content}){
  let header, subcategory;

  content.programCategories.nodes.map(node => {
    if (node.parent === null){
      return header = node.name      
    }
    else{
      return(
        header = node.parent.node.name,
        subcategory = node.name
      )
    }
  })

  return(
    <Fragment key={content.title}>
      <BannerSection banner={content.bannerImg} header={header} subcategory={subcategory} title={content.title}/>
      <section className="px-5 py-10 ">
        <div className="container mx-auto">
          <div className="programs-inner text-darkblue px-10 lg:px-28" dangerouslySetInnerHTML={{__html: content.content}}/>
        </div>
      </section>
    </Fragment>
  )
}

function ArticleBody(){
  let {nodeid} = useParams(); //GET THE SLUG IN URL

  const [newslug, setSlug] = useState("");

  const variables = {
      slug: nodeid, //TURN SLUG TO ARRAY
  }

  const {data, isLoading, error} = useQuery({
      queryFn: () => request(mmfmpConfig.apiUrl, queries.selectedPrograms, variables),
      queryKey: ["programBy", { newslug }],
      staleTime: Infinity,
      cacheTime: 0,
  });

  if (isLoading) return "Loading...";

  if (data.programBy === null){
      return <Error404/>
  }

  if(nodeid !== data.programBy.slug){
    setSlug(nodeid)
  }
  
  return <RenderProgramsContent content={data.programBy}/>

}

export default function WhatWeDoInner(){
  return (
    <QueryClientProvider client={queryClient}>
      <ArticleBody/>
    </QueryClientProvider>
  )
}