import {  gql } from "graphql-request";

export const queries = {
    newsQueries : gql`
    query queryNews {
        newsposts(
            where: {
                orderby: {
                    field: DATE, 
                    order: DESC
                }, 
                status: PUBLISH
            }
            first: 100
        ){
            edges {
                node {
                    id
                    mediaCategories {
                        nodes {
                            id
                            name
                        }
                    }
                    title
                    blurb
                    date
                    slug
                    featuredImage {
                        node {
                            id
                            sourceUrl
                        }
                    }
                }
            }
        }
    }`,

    newsQueries3 : gql`
    query queryNews {
        newsposts (
            where: {
                orderby: {
                    field: DATE, 
                    order: DESC
                }, 
                status: PUBLISH
            }
            first: 3
        ){
            edges {
                node {
                    id
                    mediaCategories {
                        nodes {
                            id
                            name
                        }
                    }
                    title
                    blurb
                    date
                    slug
                    featuredImage {
                        node {
                            id
                            sourceUrl
                        }
                    }
                }
            }
        }
    }`,

    newsData : gql`
    query newsDetails($slug: String!) {
        newsBy(
            slug: $slug
        ){
            id
            mediaCategories {
                nodes {
                    id
                    name
                }
            }
            title
            content
            blurb
            date
            slug
            featuredImage {
                node {
                    id
                    sourceUrl
                }
            }
        }
    }`,

    relatedData : gql`
    query relatedNew(
        $notIn: [ID] 
        $categoryName: String!
    ){
        newsposts(
            where: {
                notIn: $notIn, 
                orderby: {
                    field: DATE, 
                    order: DESC
                }, 
                newsCategorySlug: $categoryName, 
                status: PUBLISH
            }
            first: 4
        ){
            edges {
                node {
                    mediaCategories {
                        nodes {
                            id
                            name
                        }
                    }
                    id
                    title
                    date
                    slug
                }
            }
        }
    }
    `,

    categoryData: gql`
    query categoryData {
        mediaCategories {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }`,

    selectedArticles: gql`
    query selectedNews(
        $categoryName: String!
    ){
        newsposts(
            where: { 
                orderby: {
                    field: NAME_IN, 
                    order: DESC
                }, 
                categoryName: $categoryName, 
                status: PUBLISH
            }
            first: 4
        ){
            edges {
                node {
                    categories {
                        nodes {
                            id
                            name
                        }
                    }
                    id
                    title
                    date
                    slug
                }
            }
        }
    }
    `,

    resourcesData: gql`
    query allResources {
        resources(
            first: 100
        ){
            nodes {
                id
                date
                title
                resourceFile
                resourceCategories {
                    nodes {
                        id
                        name
                    }
                }
            }
        }
    }
    `,
    resourcesCategoryData: gql`
    query allResources (
        $resourceCategorySlug: String!
    ){
        resources(
            where: {
                orderby: {
                    field: DATE,
                    order: DESC
                }, 
                resourceCategorySlug: $resourceCategorySlug
            }
            first: 100
        ){
            nodes {
                id
                date
                title
                resourceFile
                resourceCategories {
                    nodes {
                        id
                        name
                    }
                }
            }
        }
    }
    `,
    resourcesCategory: gql`
    query dataResources (
        $slug: [String]
    ){
        resourceCategories (
            where: {
                slug: $slug
            }
        ){
            nodes {
                id
                name
                slug
                description
                parent {
                    node {
                        id
                        name
                        description
                    }
                }
                resources (
                    first: 100,
                    where: {
                        orderby: {
                            field: DATE, 
                            order: DESC
                        }
                    }
                ){
                    nodes {
                        id
                        date
                        title
                        resourceFile
                        hideDate
                    }
                }
            }
        }
    }
    `,
    downloadModule: gql`
    query downloadData {
        resourceCategories(first: 10, where: {parent: 0, order: ASC, orderby: TERM_ORDER}) {
            nodes {
            id
            name
            description
            slug
            children(where: {order: ASC, orderby: NAME}) {
                edges {
                node {
                    id
                    name
                    slug
                    resources(first: 4) {
                    nodes {
                        id
                        title
                        resourceFile
                        date
                        hideDate
                    }
                    }
                }
                }
            }
            resources(first: 4) {
                nodes {
                id
                title
                resourceFile
                date
                hideDate
                }
            }
            }
        }
    }
    `,
    homeBanner: gql`
    query heroBanner {
        bannerModules(
            first: 3,
            where: {
                orderby: {
                    field: DATE,
                    order: DESC
                }
            }
        ){
            nodes {
                id
                date
                title
                blurbText
                bannerType
                bannerSrc
                linkType
                linkText
                mp4VideoSrc
                ogvVideoSrc
                webmVideoSrc
            }
        }
    }
    `,
    swmisData: gql`
    query swmisQuery {
        swmisposts(
            first: 10,
            where: {
                orderby: {
                    field: DATE,
                    order: DESC
                }
            }
        ){
            nodes {
                id
                title
                embedUrl
                content
                featuredImage {
                    node {
                        id
                        sourceUrl
                    }
                }
            }
        }
    }`,
    searchData: gql`
    query querySearch (
        $search: String!
    ){
        newsposts(
            first: 100
            where: {
                search: $search, 
                orderby: {
                    field: DATE,
                    order: DESC
                }
            }
        ){
            nodes {
                id
                title
                slug
                blurb
                mediaCategories {
                    nodes {
                        id
                        name
                    }
                }
            }
        }
        
        resources(
            first: 100
            where: {
                search: $search,
                orderby: {
                    field: DATE,
                    order: DESC
                }
            }
        ){
            nodes {
                id
                title
                resourceFile
                resourceCategories(where: {parent: 0}) {
                    nodes {
                        id
                        name
                    }
                }
            }
        }
        posts(
            first: 100,
            where: {
                search: $search,
                orderby: {
                    field: DATE,
                    order: DESC
                }
            }
        ){
            nodes {
                id
                title
                excerpt
                slug
                tags {
                    nodes {
                    id
                    name
                    }
                }
            }
        }
        programs(
            where: {
                orderby: {
                    field: TITLE, 
                    order: ASC
                }, 
                search: $search
            }
            first: 100
        ) {
            nodes {
                id
                title
                blurb
                slug
                programCategories {
                    nodes {
                        id
                        name
                        parent {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }`,
    programsData: gql`
    query programsData {
        programCategories(where: {parent: 0}) {
            nodes {
                id
                name
                programs {
                    nodes {
                        id
                        title
                        slug
                        blurb
                        content
                        ogImg
                        bannerImg
                        featuredImage {
                            node {
                                id
                                mediaItemUrl
                            }
                        }
                    }
                }
                children {
                    edges {
                        node {
                            id
                            name
                            programs {
                                nodes {
                                    id
                                    title
                                    blurb
                                    slug
                                    ogImg
                                    bannerImg
                                    featuredImage {
                                        node {
                                            id
                                            mediaItemUrl
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    `,
    selectedPrograms: gql`
    query selectedData($slug: String!) {
        programBy(
            slug: $slug
        ){
            id
            title
            slug
            blurb
            content(format: RENDERED)
            ogImg
            bannerImg
            featuredImage {
                node {
                    id
                    mediaItemUrl
                }
            }
            programCategories {
                nodes {
                    id
                    name
                    parent {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
    `,
}