import React, { Component } from 'react';
import { Link } from "react-router-dom";

import {PageBanner} from '../CommonComponents';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { PlayIcon } from '@heroicons/react/20/solid';
import Pagination from "react-js-pagination";

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from '../config';
import { queries } from '../GraphQL/Queries';

//DATE PACKAGE FORMAT
import moment from 'moment';

//CALL THIS EVERY TIME NEED TO USE THE QUERY
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const pagebanner = [
  {
    bannersrc: '/images/mmfmp-news-banner.jpg',
    header: 'NEWS & UPDATES',
    copy: 'Be informed on the latest updates concerning new and ongoing projects, auxiliary efforts, and all reports concerning the MMFMP.'
  }
]

function formatDate(inputDate){
    return moment(inputDate).format("MMMM DD, YYYY")
}

function formatYear(getYear){
  return moment(getYear).format("YYYY")
}

function NewsListing({articlelist}) {
  return (
    <section className="md:px-5">
      <div className="container mx-auto ">
      {
        articlelist.map(({node}) => (
          <Link key={node.id} to={'/news/' + node.slug}>
            <div className="mb-12 flex max-lg:flex-col">
              <img src={node.featuredImage !== null ? node.featuredImage.node.sourceUrl : "/images/mmfmp-newslanding-img-default.jpg"} alt={node.title}  className="object-cover lg:max-2xl:w-1/2 basis-2/4"/>
              <div className="text-blue barlow-regular bg-neutral-200 w-full relative basis-2/4"> 
                <div className="2xl:absolute 2xl:top-1/2 2xl:-translate-y-1/2 p-8">
                  <p dangerouslySetInnerHTML={{__html: formatDate(node.date)}} className="leading-7 mb-3 text-sm"/>
                    {node.mediaCategories.nodes.map((category, index) => (
                      <span key={index} className="leading-10 font-medium text-sm">{category.name}{index < node.mediaCategories.nodes.length - 1 ? ', ' : ''}</span>
                    ))}
                      <p dangerouslySetInnerHTML={{__html: node.title}} className="leading-7 bakbak-one-regular text-2xl"/>
                      <p dangerouslySetInnerHTML={{__html: node.blurb}} className="leading-7 text-sm"/>
                      <p className="underline text-sm mt-5">Read more</p>
                   </div>
                 </div>
            </div>
          </Link>
        ))
      }
      </div>
    </section>
  )
}

class ArticlePagination extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentPage: 1,
      resultsPerPage: 4
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidUpdate (nextProps) {
    if (nextProps.articlelist !== this.props.articlelist){
      this.setState({currentPage: 1});
    }
  }

  handlePageChange(pageNumber) {
    this.setState({currentPage: pageNumber});
  }

  render() {
    let { currentPage, resultsPerPage } = this.state;

    let { articlelist } = this.props;

    const lastPage = currentPage * resultsPerPage;
    const firstPage = lastPage - resultsPerPage;
    const currentResults = articlelist.slice(firstPage, lastPage); 

    return (
      <section className="px-5 py-10">
        <NewsListing articlelist={currentResults}/>
        <div className="container mx-auto py-10 flex justify-center news-pagination">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={4}
            totalItemsCount={articlelist.length}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
            hideFirstLastPages={true}
            prevPageText={<PlayIcon className="rotate-180 block h-6 w-6 " />}
            nextPageText={<PlayIcon className="block h-6 w-6 "/>}
            activeClass="bg-blue text-white"
            activeLinkClass="text-white"
            itemClass="barlow-regular bg-gray-200 text-gray-500 text-lg mx-1 px-4 py-1"
            innerClass="flex"
          /> 
        </div>
      </section>
    );
  }
}

class NewsFilterSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      filteredArticlesData: props.filteredarticles,
      filteredCategoriesData: props.filteredcategories,
      categoryvalue: 'Select Category',
      yearvalue: 'Select Year',
      headervalue: 'LATEST ARTICLES',
    };
  }

  filterArticles(categoryselect = this.state.categoryvalue, yearselect = this.state.yearvalue) {
    let filteredArticles = this.props.filteredarticles;

    if (categoryselect !== 'All Articles' && categoryselect !== 'Select Category') {
      filteredArticles = filteredArticles.filter(item =>
        item.node.mediaCategories.nodes.some(category => category.name === categoryselect)
      );
    }

    if (yearselect !== 'All Articles' && yearselect !== 'Select Year') {
      filteredArticles = filteredArticles.filter(({node}) => formatYear(node.date) === yearselect);
    }

    let header = `LATEST ${categoryselect !== 'All Articles' && categoryselect !== 'Select Category' ? categoryselect : ''} ${yearselect !== 'All Articles' && yearselect !== 'Select Year' ? 'FOR ' + yearselect : ''}`.trim();

    if (filteredArticles.length === 0) {
      header = `There are no articles under ${categoryselect !== 'All Articles' && categoryselect !== 'Select Category' ? categoryselect : ''} ${yearselect !== 'All Articles' && yearselect !== 'Select Year' ? 'for ' + yearselect : ''}`.trim();
    }

    this.setState({
      filteredArticlesData: filteredArticles,
      categoryvalue: categoryselect,
      yearvalue: yearselect,
      headervalue: header || 'LATEST ARTICLES'
    });
  }

  filterByCategory(categoryselect) {
    this.filterArticles(categoryselect, this.state.yearvalue);
  }

  filterByYear(yearselect) {
    this.filterArticles(this.state.categoryvalue, yearselect);
  }

  render() {
    let years = this.props.filteredarticles.map(({node}) => {
      return formatYear(node.date);
    });

    let uniqueYears = years.filter((y, index) => {
      return years.indexOf(y) === index;
    });

    let categoryfilter = this.props.filteredcategories.map(({node}) => {
      return node.name;
    });

    let uniqueCategories = categoryfilter.filter((y, index) => {
      return categoryfilter.indexOf(y) === index;
    });

    let { categoryvalue, yearvalue, filteredArticlesData, headervalue } = this.state;

    if (!filteredArticlesData){
      return <div>Loading</div>;
    }
    else {
      return (
        <>
          <section className="py-5 sm:py-10 px-5">
            <div className="container mx-auto w-full lg:w-1/2">
              <div className="grid sm:grid-cols-2 gap-5 sm:gap-10">
                <div className="bg-darkblue relative">
                  <Menu>
                    <MenuButton className="flex justify-between items-center text-white barlow-regular text-base w-full px-5 py-3">
                      {categoryvalue} 
                      <PlayIcon className="block h-4 w-4 rotate-90 absolute right-5"/>
                    </MenuButton>
                    <MenuItems anchor="bottom" className="bg-darkblue w-3/4 sm:w-auto">
                      <MenuItem className="text-white barlow-regular text-base block my-3 hover:underline w-full text-left pl-3 pr-16" as="button" onClick={() => this.filterByCategory('All Articles')}>Show All Articles</MenuItem>
                      {
                        uniqueCategories.sort().reverse().map((category, index) =>{
                          return(
                            <MenuItem key={index} className="text-white barlow-regular text-base block my-3 hover:underline w-full text-left pl-3 pr-16" as="button" onClick={() => this.filterByCategory(category)}>{category}</MenuItem>
                          )
                        })
                      }
                    </MenuItems>
                  </Menu>
                </div>
                <div className="bg-darkblue relative">
                  <Menu>
                    <MenuButton className="flex justify-between items-center text-white barlow-regular text-base w-full px-5 py-3">
                      {yearvalue} 
                      <PlayIcon className="block h-4 w-4 rotate-90 absolute right-5"/>
                    </MenuButton>
                    <MenuItems anchor="bottom" className="bg-darkblue">
                      <MenuItem className="text-white barlow-regular text-base block my-3 hover:underline w-full text-left pl-3 pr-16" as="button" onClick={() => this.filterByYear('All Articles')}>Show All Articles</MenuItem>
                      {
                        uniqueYears.sort().reverse().map((year, index) =>{
                          return(
                            <MenuItem key={index} className="text-white barlow-regular text-base block my-3 hover:underline w-full text-left pl-3 pr-16" as="button" onClick={() => this.filterByYear(year)}>{year}</MenuItem>
                          )
                        })
                      }
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            </div>
          </section>
          <div className="container mx-auto px-5 ">
            {
              filteredArticlesData.length === 0
              ?
                <>
                  <h1 className="text-4xl barlow-bold text-blue uppercase mb-10">{headervalue}</h1>
                </>
              :
                <>
                  <h1 className="text-4xl barlow-bold text-blue uppercase">{headervalue}</h1>
                  <ArticlePagination articlelist={filteredArticlesData}/>
                </>
            }
          </div>
        </>
      );
    }
  }
}

function GetNewsData(){
  const { data, isLoading, error } = useQuery("newsposts", () => {
    return request(mmfmpConfig.apiUrl, queries.newsQueries);
  });

  const dataCategory = useQuery("mediaCategories", () => {
    return request(mmfmpConfig.apiUrl, queries.categoryData);
  });

  if (isLoading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;

  return <NewsFilterSection filteredarticles={data.newsposts.edges} filteredcategories={dataCategory.data.mediaCategories.edges}/>
}

export default function NewsLanding(){
  return (
    <>
      <section id="banner"><PageBanner pagebanner={pagebanner}/></section>
      <QueryClientProvider client={queryClient}>
        <GetNewsData />
      </QueryClientProvider>
    </>
  )
}