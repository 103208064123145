import React from 'react';
import { Link } from "react-router-dom";

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from '../config.js';
import { queries } from '../GraphQL/Queries.js';

function DisplayVideoBanner({homepagebanner}) {
  return (
    <>
    {
        homepagebanner.map(banner => {
            return(
                banner.bannerType === 'video'
                ?
                <div className="video-banner relative flex items-center justify-start h-screen overflow-hidden" key={banner.id}>
                    <div className="container mx-auto z-30 grid grid-cols-1 xl:grid-cols-2" >
                        <div className="relative z-30 px-3 py-5 text-white">
                            <h1 className="bakbak-one-regular text-4xl" dangerouslySetInnerHTML={{__html: banner.title}}/>
                            {
                                banner.blurbText !== undefined && banner.blurbText !== ""
                                ?
                                <p className="barlow-regular text-lg" dangerouslySetInnerHTML={{__html: banner.blurbText}}/>
                                :
                                ""
                            }
                            <div className="my-5">
                            {
                                banner.linkText !== undefined && banner.linkText !== ""
                                ?
                                    banner.linkType === "int"
                                    ?
                                    <Link to={banner.linkText} className="leading-10 barlow-bold text-base rounded-3xl border border-white px-8 py-2">Learn more</Link>
                                    :
                                    <a href={banner.linkText} target="_blank" rel="noopener noreferrer" className="leading-10 barlow-bold text-base rounded-3xl border border-white px-8 py-2">Learn more</a>
                                :
                                ""
                            }
                            </div>
                        </div>
                    </div>
                    <video playsInline="playsInline" autoPlay="autoPlay" muted="muted" loop="loop" className="absolute z-10 w-auto min-w-full min-h-full max-w-none">
                        <source src={banner.mp4VideoSrc}  type="video/mp4" />
                        <source src={banner.ogvVideoSrc}  type='video/ogg; codecs="theora, vorbis"' />
                        <source src={banner.webmVideoSrc} type='video/webm; codecs="vp8.0, vorbis"' />
                    </video>
                </div>
                :
                <section style={{backgroundImage: 'url(' + banner.bannerSrc + ')'}} key={banner.id} className="bg-no-repeat bg-cover pt-20 pb-16 h-full">
                    <div className="container mx-auto z-30 grid grid-cols-1 xl:grid-cols-2">
                        <div className="px-3 py-5 flex flex-col justify-start text-white">
                            <h1 dangerouslySetInnerHTML={{__html: banner.title}} className="barlow-bold uppercase text-3xl"/>
                            {
                                banner.blurbText !== undefined && banner.blurbText !== ""
                                ?
                                <p className="barlow-regular text-lg" dangerouslySetInnerHTML={{__html: banner.blurbText}}/>
                                :
                                ""
                            }
                            <div className="my-5">
                            {
                                banner.linkText !== undefined && banner.linkText !== ""
                                ?
                                    banner.linkType === "int"
                                    ?
                                    <Link to={banner.linkText} className="leading-10 barlow-bold text-base rounded-3xl border border-white px-8 py-2">Learn more</Link>
                                    :
                                    <a href={banner.linkText} target="_blank" rel="noopener noreferrer" className="leading-10 barlow-bold text-base rounded-3xl border border-white px-8 py-2">Learn more</a>
                                :
                                ""
                            }
                            </div>
                        </div>
                    </div>
                </section>
            )
        })
    }
    </>
  )
}

export default function VideoBanner(){
  const { data, isLoading, error } = useQuery("bannerModules", () => {
    return request(mmfmpConfig.apiUrl, queries.homeBanner);
  });

  if (isLoading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;

  return <DisplayVideoBanner homepagebanner={data.bannerModules.nodes}/>
}
