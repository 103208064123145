import React from 'react';

export default function Error404 (){
    return (
        <section className="pt-56 pb-32">
            <div className="container mx-auto">
                <div className="grid px-5 lg:px-0 lg:grid-cols-8">
                    <div className="lg:col-start-3 lg:col-span-4 text-blue">
                        <h1 className="bakbak-one-regular text-3xl mb-5">404 - Page Not Found</h1>
                        <p className="barlow-regular text-xl">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
