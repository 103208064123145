import React from 'react';
import { Link, useParams } from "react-router-dom";
import { useState } from "react";

import {PageBanner} from '../CommonComponents'

import Error404 from '../Error404';

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from '../config';
import { queries } from '../GraphQL/Queries';

//DATE PACKAGE FORMAT
import moment from 'moment';

//CALL THIS EVERY TIME NEED TO USE THE QUERY
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
});

const pagebanner = [
  {
    bannersrc: '/images/mmfmp-news-banner.jpg',
    header: 'NEWS & UPDATES',
    copy: 'Be informed on the latest updates concerning new and ongoing projects, auxiliary efforts, and all reports concerning the MMFMP.'
  }
]

function formatDate(inputDate){
    return moment(inputDate).format("MMMM DD, YYYY")
}

function RelatedArticles({dataid, datacategory}){
    let id = dataid
    let category= datacategory

    const variables = {
        notIn: id,
        categoryName: category
    }

    const { data, isLoading, error } = useQuery("newsposts", () => {
        return request(mmfmpConfig.apiUrl, queries.relatedData, variables);
    });

    if (isLoading) return "Loading...";
    if (error) return <pre>{error.message}</pre>;

    return(
        <>
            <p className="text-gray-600 barlow-bold mb-5 uppercase">Related Articles</p>
            {
                data.newsposts.edges.map(({node}) => {
                return (
                    <div className="text-gray-600 barlow-regular pr-5 mb-5 relative" key={node.title}>
                        <Link to={'/news/' + node.slug}>
                        {node.mediaCategories.nodes.map((category, index) => (
                            <span key={index} className="text-xs uppercase">{category.name}{index < node.mediaCategories.nodes.length - 1 ? ', ' : ''}</span>
                        ))}
                            <p className="barlow-bold text-sm" dangerouslySetInnerHTML={{__html: node.title}} />
                            <p className="text-xs" dangerouslySetInnerHTML={{__html: formatDate(node.date)}} />                            
                            <p className="underline mt-3 text-xs">Read more</p>
                            <hr className="mt-7"/>
                        </Link>
                    </div>
                )
                })
            }  
        </>
    )
}

let nID // GLOBAL VAR TO PASS ON
let cName // GLOBAL VAR TO PASS ON

function ArticleBody(){
    let {nodeid} = useParams(); //GET THE SLUG IN URL

    const [newslug, setSlug] = useState("");

    const variables = {
        slug: nodeid, //TURN SLUG TO ARRAY
    }

    const {data, isLoading, error} = useQuery({
        queryFn: () => request(mmfmpConfig.apiUrl, queries.newsData, variables),
        queryKey: ["newsBy", { newslug }],
        staleTime: Infinity,
        cacheTime: 0,
    });

    if (isLoading) return "Loading...";

    if (data.newsBy === null){
        return <Error404/>
    }

    if(nodeid !== data.newsBy.slug){
        setSlug(nodeid)
    }

    nID = data.newsBy.id
    cName = data.newsBy.mediaCategories.nodes[0].name

    return (
        <>
            <PageBanner pagebanner={pagebanner}/>
            <div className="container mx-auto px-5 py-16 xl:w-4/5">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-16">
                    <div className="col-span-2 news-inner-style">
                        <div key={data.newsBy.title}>
                            <div className="bg-cyan-800 p-5 mb-3" >
                                <p className="text-white barlow-regular text-base text-left mb-5" dangerouslySetInnerHTML={{__html: formatDate(data.newsBy.date)}}/>
                                { data.newsBy.featuredImage !== null ? <img src={data.newsBy.featuredImage.node.sourceUrl} alt={data.newsBy.title} className="h-auto max-w-full mx-auto" /> : "" }
                                <h1 className="text-white text-3xl lg:text-4xl barlow-bold text-left mt-5" dangerouslySetInnerHTML={{__html: data.newsBy.title}}/>
                            </div>
                            {data.newsBy.mediaCategories.nodes.map((category, index) => (
                                <span key={index} className="text-blue barlow-regular text-sm">{category.name}{index < data.newsBy.mediaCategories.nodes.length - 1 ? ', ' : ''}</span>
                            ))}
                            <p className="text-blue barlow-regular text-base mt-6" dangerouslySetInnerHTML={{__html: data.newsBy.content}} />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <RelatedArticles dataid={nID} datacategory={cName}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default function NewsInnerCMS(){
  return (
    <QueryClientProvider client={queryClient}> 
        <ArticleBody/>
    </QueryClientProvider>
  )
}