import React from 'react';
import { Link } from "react-router-dom";

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from '../config';
import { queries } from '../GraphQL/Queries';

//DATE PACKAGE FORMAT
import moment from 'moment';

function formatDate(inputDate){
    return moment(inputDate).format("MMMM DD, YYYY")
}

export default function NewsSectionCMS(){
  const { data, isLoading, error } = useQuery("newsposts", () => {
    return request(mmfmpConfig.apiUrl, queries.newsQueries3);
  });

  if (isLoading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;

  return (
    <section className="px-5 pt-10 pb-14 bg-lightgrey">
      <div className="mb-5 container mx-auto ">
        <h1 className="text-blue text-2xl barlow-bold uppercase">NEWS & UPDATES</h1>
        <p className="text-blue barlow-regular">Be informed on the latest updates concerning new and ongoing projects, auxiliary efforts, and all reports concerning the MMFMP.</p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-8 ">
          {
          data.newsposts.edges.map(({node}) => (
            <div className="text-blue barlow-regular text-sm pr-5 mb-5 relative" key={node.id}>
              <Link to={'/news/' + node.slug}>
              {node.mediaCategories.nodes.map((category) => (
              <p key={category.id} className="text-xs uppercase" dangerouslySetInnerHTML={{__html: category.name}} />
            ))}
              <p className="barlow-bold text-xl mb-3" dangerouslySetInnerHTML={{__html: node.title}} />
              <p className="mb-3" dangerouslySetInnerHTML={{__html: node.blurb}} />
              <p className="mb-3" dangerouslySetInnerHTML={{__html: formatDate(node.date)}} />
              <div className="lg:absolute lg:-bottom-10">
                <p className="underline mt-4">Read more</p>
              </div>
              </Link>
            </div>
          ))
          }
        </div>
      </div>
      <div className="container mx-auto mt-10 lg:mt-20">
        <Link to="/news" className="rounded-3xl border border-blue-900 px-8 py-2 relative table-cell">
          <p className="barlow-bold text-blue">View all</p>
        </Link>
      </div>
    </section>
  )
}