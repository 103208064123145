import React, { Fragment, Component } from 'react';
// import { Link } from "react-router-dom";
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import Pagination from "react-js-pagination";
import { PlayIcon } from '@heroicons/react/20/solid';
// import Slider from "react-slick";

//DATE PACKAGE FORMAT
import moment from 'moment';

function FormatDate(inputDate){
  return moment(inputDate).format("MMMM DD, YYYY")
}

function PageBanner({pagebanner}) {
  return (
    <>
    {
      pagebanner.map((banner, index) => {
        return(
          <section style={{backgroundImage: 'url(' + banner.bannersrc + ')'}} key={index} className="bg-no-repeat bg-cover pt-24 pb-16 h-full max-lg:pb-10">
            <div className="container mx-auto px-3 py-24">
              <div className="lg:w-1/2 xl:w-1/3">
                <h1 dangerouslySetInnerHTML={{__html: banner.header}} className="text-white bakbak-one-regular uppercase text-4xl"/>
                <p dangerouslySetInnerHTML={{__html: banner.copy}} className="text-white barlow-regular text-xl"/>
              </div>
            </div>
          </section>
        )
      })
    }
    </>
  )
}

// const partnerlogos = [
//     {
//         "img": "images/homepage/mmfmp-home-partnerlogo-worldbank.png",
//         "link": "https://ida.worldbank.org/en/home"
//     },
//     {
//         "img": "images/homepage/mmfmp-home-partnerlogo-aiib.png",
//         "link": "https://www.aiib.org/en/index.html"
//     },
//     {
//         "img": "images/homepage/mmfmp-home-partnerlogo-03.png",
//         "link": "https://www.dpwh.gov.ph/dpwh/"
//     }
// ]

// function PartnerSlider() {
//   var settings = {
//     dots: false,
//     infinite: false,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     arrows: true,
//     draggable: false,
//     responsive: [
//          {
//             breakpoint: 1024,
//             settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 1,
//             }
//         },
//         {
//             breakpoint: 768,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//             }
//         }
//     ]
//   };

//   return (
//     <section style={{backgroundColor: '#e5e4dc'}} className="p-5">
//         <div className="container mx-auto">
//             <h1 className="barlow-bold text-gray-600 text-2xl mb-8">PARTNERS</h1>
//             <div className="px-10">
//                 <Slider {...settings}>
//                 {
//                     partnerlogos.map(logo => {
//                         return (
//                             <div key={logo.img}>
//                                 <a href={logo.link} target="_blank" rel="noopener noreferrer">
//                                     <img src={logo.img} alt="" className="lg:grayscale hover:grayscale-0 mx-auto"/>
//                                 </a>
//                             </div>
//                         )
//                     })
//                 }
//                 </Slider>
//             </div>
//         </div>
//     </section>
//   );
// }

function PDFListing({pdffiles}){
  return(
    <div className="pt-3 pb-10 md:px-5">
      {
        pdffiles.map((pdf, index) =>{
          return (
            <Fragment key={index}>
              <a href={pdf.resourceFile} target="_blank" rel="noopener noreferrer" >
                <div className="flex py-5 pl-4 sm:pl-8" >
                  <div className="basis-full">
                    <p className="barlow-bold text-base mb-1" dangerouslySetInnerHTML={{__html: pdf.title}} />
                    {
                      pdf.hideDate === true ? "" : <p className="barlow-regular text-sm" dangerouslySetInnerHTML={{__html: FormatDate(pdf.date)}} />
                    }
                  </div>
                  <DocumentArrowDownIcon className="basis-1/6 block h-6 w-6" aria-hidden="true" />
                </div>
              </a>
              <hr className="border border-gray-500"/> 
            </Fragment>
          )
        })
      }
    </div>   
  )
}

function PDFListing2({pdffiles, dateshow}){
  return(
    <div className="pt-5 pb-10 md:px-5">
      {
        pdffiles.map((pdf, index) =>{
          return (
            <Fragment key={index}>
              <a href={pdf.resourceFile} target="_blank" rel="noopener noreferrer" >
                <div className="flex py-5 pl-4 sm:pl-8 lg:pl-10 xl:pl-12" >
                  <div className="basis-full">
                    <p className="barlow-bold text-base mb-1" dangerouslySetInnerHTML={{__html: pdf.title}} />
                    {
                      dateshow === 'no' ? "" : <p className="barlow-regular text-sm" dangerouslySetInnerHTML={{__html: FormatDate(pdf.date)}} />
                    }
                  </div>
                  <DocumentArrowDownIcon className="basis-1/6 block h-6 w-6" aria-hidden="true" />
                </div>
              </a>
              <hr className="border border-gray-500"/> 
            </Fragment>
          )
        })
      }
    </div>  
  )
}

class PDFPagination extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentPage: 1,
      resultsPerPage: 10
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidUpdate (nextProps) {
    if (nextProps.pdflist !== this.props.pdflist){
      this.setState({currentPage: 1});
    }
  }

  handlePageChange(pageNumber) {
    this.setState({currentPage: pageNumber});
  }

  render() {
    let { currentPage, resultsPerPage } = this.state;

    let { pdflist } = this.props;

    const lastPage = currentPage * resultsPerPage;
    const firstPage = lastPage - resultsPerPage;
    const currentResults = pdflist.slice(firstPage, lastPage); 

    return (
      <section className="px-5 pb-10">
        <PDFListing pdffiles={currentResults}/>
        <div className="container mx-auto py-10 flex justify-center news-pagination">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={10}
            totalItemsCount={pdflist.length}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
            hideFirstLastPages={true}
            prevPageText={<PlayIcon className="rotate-180 block h-4 w-4 pb-1" />}
            nextPageText={<PlayIcon className="block h-4 w-4 pt-1"/>}
            activeClass="bg-blue text-white"
            activeLinkClass="text-white"
            itemClass="barlow-regular bg-gray-200 text-gray-500 text-sm mx-1 px-3 py-1"
            innerClass="flex"
          /> 
        </div>
      </section>
    );
  }
}

class PDFPagination2 extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentPage: 1,
      resultsPerPage: 10
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidUpdate (nextProps) {
    if (nextProps.pdflist !== this.props.pdflist){
      this.setState({currentPage: 1});
    }
  }

  handlePageChange(pageNumber) {
    this.setState({currentPage: pageNumber});
  }

  render() {
    let { currentPage, resultsPerPage } = this.state;

    let { pdflist, color } = this.props;

    const lastPage = currentPage * resultsPerPage;
    const firstPage = lastPage - resultsPerPage;
    const currentResults = pdflist.slice(firstPage, lastPage); 

    return (
      <section className="px-5 pb-10">
        <PDFListing2 color={color} pdffiles={currentResults}/>
        <div className="container mx-auto py-10 flex justify-center news-pagination">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={10}
            totalItemsCount={pdflist.length}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
            hideFirstLastPages={true}
            prevPageText={<PlayIcon className="rotate-180 block h-4 w-4 pb-1" />}
            nextPageText={<PlayIcon className="block h-4 w-4 pt-1"/>}
            activeClass="bg-blue text-white"
            activeLinkClass="text-white"
            itemClass="barlow-regular bg-gray-200 text-gray-500 text-sm mx-1 px-3 py-1"
            innerClass="flex"
          /> 
        </div>
      </section>
    );
  }
}



export { 
    PageBanner, 
    // PartnerSlider,
    PDFListing,
    PDFListing2,
    PDFPagination,
    PDFPagination2,
} ;

