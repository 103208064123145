import React from 'react';
import {PageBanner} from '../CommonComponents.js';

const pagebanner = [
  {
    bannersrc: '/images/whoarewe/mmfmp-whoarewe-banner.jpg',
    header: 'Our Goal',
    copy: 'To improve flood management in selected areas of Metro Manila.'
  }
]

function About() {
  return (
    <section className="px-5 py-10">
      <div className="container mx-auto text-darkblue">
        <h1 className=" bakbak-one-regular uppercase text-4xl mb-5">ABOUT MMFMP</h1>
         <div className="barlow-regular text-base grid lg:grid-cols-12">
          <div className="lg:col-span-11">
              <p className="mb-5">In the wake of the devastation caused by Typhoon Ondoy (internationally named Typhoon Ketsana) flooding Metro Manila in 2009, the Philippine government took action by preparing the Flood Management Master Plan for Metro Manila and its surrounding areas, which was approved by the National Economic and Development Authority (NEDA) Board in 2012.</p>
              <p>The Metro Manila Flood Management Project (MMFMP) serves as Phase 1 of this Master Plan. With the technical and financial support of the World Bank Group—International Bank for Reconstruction and Development (IBRD), the Asian Infrastructure Investment Bank (AIIB), and the Government of the Philippines, MMFMP brings together all partners and stakeholders, from the national government down to affected community members, to work towards the common goal of improving flood management in target areas in Metro Manila.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

const components = [
  {
    title: "Component 1 (DPWH)",
    subhead: "Modernization of Drainage Areas",
    copy: "This component covers the upgrade of 26 existing pumping stations and their related infrastructure, like floodgates and trash racks. It will also build 4 new pumping stations, enhance drainage systems, increase water storage capacity, and add modern dredging and cleaning equipment.",
    img: "/images/whoarewe/mmfmp-whoarewe-component1.png"
  },
  {
    title: "Component 2 (MMDA)",
    subhead: "Minimizing Solid Waste in Waterways",
    copy: "This component seeks to improve the solid waste management (SWM) system in Metro Manila and its target communities, to contribute to minimizing the volume of solid wastes in waterways. It includes establishing new SWM technologies, enhancing community-based SWM system, harmonizing SWM plans and practices, raising awareness, capacitating stakeholders, among others.",
    img: "/images/whoarewe/mmfmp-whoarewe-component2.png"
  },
  {
    title: "Component 3 (DPWH)",
    subhead: "Participatory Housing and Resettlement",
    copy: "This component will relocate people affected by the project from the target drainage areas. It will also provide support for those who were relocated before the project started and offer community-based help in the new resettlement areas.",
    img: "/images/whoarewe/mmfmp-whoarewe-component3.png"
  },
  {
    title: "Component 4 (DPWH/MMDA)",
    subhead: "Project Management and Coordination",
    copy: "This component will fund project management offices to oversee and coordinate project activities and provide consulting services to help with implementation.",
    img: "/images/whoarewe/mmfmp-whoarewe-component4.png"
  }
]

function Components({components}) {
  return (
    <section className="px-5 pt-5 pb-10">
      <div className="container mx-auto text-darkblue">
        <h1 className=" bakbak-one-regular uppercase text-4xl mb-5">MMFMP COMPONENTS</h1>
        <p className="barlow-regular text-base">The objective of "minimizing wastes in waterways" is a component of MMFMP Phase I. A total of four major components make up the Project.</p>
        <div className="px-5 md:px-10 lg:px-20">
          {
            components.map((component, index) =>{
              return(
                <div className={index === 1 ? "lg:flex lg:items-center text-white rounded-xl px-10 py-5 my-10" : "lg:flex lg:items-center border-4 border-darkblue rounded-xl px-10 py-5 my-10"} key={component.title} style={{backgroundColor: index === 1 ? '#495881' : ""}}> 
                  <img src={component.img} alt={component.title} className="h-auto max-w-full mx-auto w-1/2 sm:w-1/3 md:w-1/4 lg:w-2/12" />
                  <div className="mt-10 lg:mt-0 lg:ml-10">
                    <h1 className="bakbak-one-regular text-3xl" dangerouslySetInnerHTML={{__html: component.title}} />
                    <p className="barlow-regular text-lg mb-5" dangerouslySetInnerHTML={{__html: component.subhead}} />
                    <p className="barlow-regular text-sm" dangerouslySetInnerHTML={{__html: component.copy}} />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

function Implementers() {
  return (
    <section className="px-5 py-24" id="about-implementers">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12">
          <div className="text-white lg:col-span-11">
            <h1 className="uppercase bakbak-one-regular text-4xl mb-10">THE IMPLEMENTERS</h1>
            <p className="barlow-regular text-base my-3">The MMFMP is a joint project co-led by the Metropolitan Manila Development Authority (MMDA) and the Department of Public Works and Highways (DPWH). DPWH leads the implementation of Components 1 and 3, while MMDA leads the implementation of Component 2 in close cooperation with Local Government Units and National Government Agencies. Both agencies have set up their own project management offices through Component 4.</p>
            <p className="barlow-regular text-base my-3">Once the project is completed, the pumping stations rehabilitated or constructed by DPWH under Component 1 will be turned over to MMDA for operations and maintenance.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

const mandates = [
  {
    img: "/images/whatwedo/mmfmp-whatwedo-mandates-solidwaste.png",
    title: "Solid Waste Disposal and Management",
    copy: "This service focuses on creating and implementing policies, standards, and projects for the proper disposal of solid waste. This includes setting up and managing sanitary landfills and other facilities, and running programs that encourage reducing, reusing, and recycling waste. Our goal is to improve waste management practices and support a more sustainable approach to handling solid waste.",
    link: "https://swmis.mmda.gov.ph/services"
  },
  {
    img: "/images/whatwedo/mmfmp-whatwedo-mandates-floodcontrol.png",
    title: "Flood Control and Sewerage Management",
    copy: "Focuses on developing and implementing policies, standards, guidelines, and regulations in addition to specific projects and programs in order to establish a coherent and efficient drainage, sewerage, and flood control system.",
    link: "https://mmda.gov.ph/2-uncategorised/3373-flood-control-and-sewerage-management-2.html"
  },
  {
    img: "/images/whatwedo/mmfmp-whatwedo-mandates-planning.png",
    title: "Development Planning",
    copy: "This involves creating long-term development plans, evaluating and preparing projects aligned with these goals, managing investments, and overseeing the implementation of said plans and programs.",
    link: "https://mmda.gov.ph/2-uncategorised/3377-development-planning-2.html"
  },
  {
    img: "/images/whatwedo/mmfmp-whatwedo-mandates-urbanrenewal.png",
    title: "Urban Renewal, Zoning and Land Use Planning, and Shelter Services",
    copy: "Involves creating and enforcing policies and programs to manage and optimize urban land use, guide city growth, improve slum areas, build housing, and provide essential social services.",
    link: "https://mmda.gov.ph/2-uncategorised/3514-urban-renewal-zoning-and-land-use-planning-and-shelter-services-2.html"
  }
]

function MandatesSection({mandates}){
  return(
    <section className="px-5 py-10" style={{backgroundColor: '#41528c'}}>
      <div className="container mx-auto text-white">
        <div className="grid lg:grid-cols-12">
          <div className="lg:col-span-6 xl:col-span-5">
            <h1 className="bakbak-one-regular text-4xl mb-3 uppercase">MMDA Mandates<br className="hidden lg:block"/> Related to MMFMP</h1>
            <p className="text-base barlow-regular">An overview of the services that the MMDA is currently running to ensure our goal of cleaner waterways in Metro Manila.</p>
          </div>         
        </div>
        <div className="mt-14 px-10 grid lg:grid-cols-12">
          <div className="lg:col-span-11">
          {
            mandates.map(mandate => {
              return(
                <div className="lg:flex lg:items-center mb-10" key={mandate.title}>
                  <img src={mandate.img} alt={mandate.img} className="h-auto max-w-full mx-auto w-1/2 sm:w-1/3 md:w-1/4 lg:w-2/12" />
                  <div className="mt-10 lg:mt-0 lg:ml-10">
                    <h1 className="bakbak-one-regular text-2xl mb-3" dangerouslySetInnerHTML={{__html: mandate.title}} />
                    <p className="barlow-regular text-base" dangerouslySetInnerHTML={{__html: mandate.copy}} />
                    <div className="mt-3">
                      <a href={mandate.link} target="_blank" rel="noopener noreferrer" className="text-white barlow-regular underline">Learn more</a>
                    </div>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
    </section>
  )
}

export default function WhoWeAreLanding() {
  return (
    <>
      <PageBanner pagebanner={pagebanner}/>
      <About/>
      <Components components={components}/>
      <Implementers/>
      <MandatesSection mandates={mandates}/>
    </>
  )
}
