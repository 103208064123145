export const mmfmpConfig = {
    //LOCAL DOMAIN
    // appDomain: "http://localhost:3000/",
    // apiUrl: "http://localhost:8080/graphql",

    //MMFMP BETA CMS
    // appDomain: "https://mmdabeta.tribalph.com",
    // apiUrl: "https://mmdacmsbeta.tribalph.com/graphql",
    // cmsDomain: "https://mmdacmsbeta.tribalph.com/",

    //Live/Production
    appDomain: "https://mmfmp.mmda.gov.ph",
    apiUrl: "https://mmfmpcms.mmda.gov.ph/graphql",
    cmsDomain: "https://mmfmpcms.mmda.gov.ph/",
}