import React from 'react';
import { PDFPagination} from '../CommonComponents';
import { ResourcesBannerSection } from './ResourcesLanding.js';
import { useParams } from "react-router-dom";
import { useState } from "react";

import Error404 from '../Error404';

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from '../config';
import { queries } from '../GraphQL/Queries';

//CALL THIS EVERY TIME NEED TO USE THE QUERY
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

function GetData(){
    let {nodeid} = useParams(); //GET THE SLUG IN URL

    const [newslug, setSlug] = useState("");

    const variables = {
        slug: nodeid, //TURN SLUG TO ARRAY
    }

    const {data, isLoading} = useQuery({
        queryFn: () => request(mmfmpConfig.apiUrl, queries.resourcesCategory, variables),
        queryKey: ["dataResources", { newslug }],
        staleTime: Infinity,
        cacheTime: 0,
    });

    if (isLoading) return "Loading...";

    if(data.resourceCategories.nodes.length===0){
        return <Error404 />;
    }

    if(nodeid !== data.resourceCategories.nodes[0].slug){
        setSlug(nodeid)
    }

    let files = data.resourceCategories.nodes[0].resources.nodes

    return (
        <>
            <ResourcesBannerSection/>
            <section style={{backgroundColor: '#e4e4dc'}} className="px-5 py-10">
                <div className="container mx-auto text-blue">    
                {
                    data.resourceCategories.nodes[0].parent !== null && data.resourceCategories.nodes[0].parent !== null
                    ?
                    <>
                        <h1 className="text-3xl uppercase bakbak-one-regular" dangerouslySetInnerHTML={{__html: data.resourceCategories.nodes[0].parent.node.name}} />
                        <p className="barlow-regular" dangerouslySetInnerHTML={{__html: data.resourceCategories.nodes[0].parent.node.description}} />
                        <div className="px-5 pt-7">
                            <div className="container mx-auto">
                                <h1 className="text-2xl bakbak-one-regular" dangerouslySetInnerHTML={{__html: data.resourceCategories.nodes[0].name}} />
                                <p className="barlow-regular" dangerouslySetInnerHTML={{__html: data.resourceCategories.nodes[0].description}} />
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <h1 className="text-3xl uppercase bakbak-one-regular" dangerouslySetInnerHTML={{__html: data.resourceCategories.nodes[0].name}} />
                        <p className="barlow-regular" dangerouslySetInnerHTML={{__html: data.resourceCategories.nodes[0].description}} />
                    </>
                }
                <PDFPagination pdflist={files}/>
                </div>
            </section>
        </>
    )
}

export default function ResourcesInner() {
    return (
        <QueryClientProvider client={queryClient}>
            <GetData />
        </QueryClientProvider>
    )
}