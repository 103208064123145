import React, { useState } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Input } from '@headlessui/react'
import { NavLink as ActiveLink, useNavigate } from 'react-router-dom';

const mainnavigation = [
    { 
        menu: 'Who We Are',
        link: '/who-we-are' 
    },
    { 
        menu: 'What We Do',
        link: '/what-we-do' 
    },
    { 
        menu: 'Where We Work',
        link: '/where-we-work' 
    }
]

const secondarynavigation = [
  { 
    menu: 'News',
    link: '/news' 
  },
  { 
    menu: 'Resources',
    link: '/resources' 
  },
  { 
    menu: 'Contact Us',
    link: '/contact' 
  }
]

const mobilenavigation = [
    { 
        menu: 'Who We Are',
        link: '/who-we-are' 
    },
    { 
        menu: 'What We Do',
        link: '/what-we-do' 
    },
    { 
        menu: 'Where We Work',
        link: '/where-we-work' 
    },
    { 
        menu: 'News',
        link: '/news' 
    },
    { 
        menu: 'Resources',
        link: '/resources' 
    },
    { 
        menu: 'Contact Us',
        link: '/contact' 
    },
]

export default function Navigation() {
    const [inputValue, setInputValue] = useState('');
    const history = useNavigate();

    const handleSearchChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const handleSearchSubmit = () => {
        if (inputValue) {
            // Update the URL params with the new search key
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('key', inputValue);
            history({ pathname: 'search', search: urlParams.toString() });

            // Reload the page
            window.location.reload();
        }
    };

    return (
        <Disclosure as="nav" className="fixed z-50 w-full" style={{backgroundColor: '#213262'}}>
            {({ open }) => (
            <>
                <div className="mx-auto py-2 lg:py-0">
                    <div className="relative">
                        {/* Mobile menu button*/}
                        <div className="absolute inset-y-0 left-5 flex items-center lg:hidden">
                            <DisclosureButton className="relative inline-flex items-center justify-center rounded-xl p-2 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-slate-400">
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                <XMarkIcon className="block h-6 w-6 text-white" aria-hidden="true" />
                                ) : (
                                <Bars3Icon className="block h-6 w-6 text-white" aria-hidden="true" />
                                )}
                            </DisclosureButton>
                        </div>
                        {/* End Mobile menu button*/}
                        {/* Logo */}
                        <div className="lg:absolute lg:top-0 lg:bottom-0 lg:ml-5 flex flex-1 items-center justify-center">
                            <a href="/"><img className="h-20 w-auto" src="/images/mmfmp-logo.png" alt="logo"/></a>
                        </div>
                        {/* End Logo */}
                        {/* Secondary navigation light blue */}
                        <div className="hidden lg:flex justify-end" style={{backgroundColor: '#3754a4'}}>
                            <div className="mr-5 py-2">
                                {secondarynavigation.map((menu) => (
                                    <ActiveLink
                                    // exact
                                    key={menu.menu}
                                    to={menu.link}
                                     className={({ isActive }) => isActive ? "border-2 border-transparent border-b-white text-white px-3 text-sm py-2 cursor-pointer barlow-bold" : "text-white border-2 border-transparent hover:border-b-white hover:text-white px-3 text-sm py-2 cursor-pointer barlow-regular"}
                                    >
                                        {menu.menu}
                                    </ActiveLink>
                                ))}
                            </div>                        
                        </div>
                        {/* End Secondary navigation light blue */}
                        {/* Main navigation dark blue */}
                        <div className="hidden lg:flex justify-end mr-7">
                            <div className="mr-5 py-4">
                                {mainnavigation.map((menu) => (
                                    <ActiveLink
                                    // exact
                                    key={menu.menu}
                                    to={menu.link}
                                    className={({ isActive }) => isActive ? "border-2 border-transparent border-b-white text-white px-3 py-4 text-base cursor-pointer uppercase barlow-bold" : "text-white border-2 border-transparent hover:border-b-white hover:text-white px-3 py-4 text-base cursor-pointer uppercase barlow-regular"}
                                    >
                                        {menu.menu}
                                    </ActiveLink>
                                ))}
                            </div>
                            <div className="flex flex-shrink-0 items-center">
                                <Input 
                                    name="search" 
                                    type="text" 
                                    className="barlow-regular text-base block rounded-3xl border border-gray-50 text-white px-3 py-1 bg-transparent placeholder:text-white w-full" 
                                    placeholder="Search"
                                    value={inputValue}
                                    onChange={handleSearchChange}
                                    onKeyDown={handleKeyPress}
                                />
                                <MagnifyingGlassIcon onClick={handleSearchSubmit} className="text-white absolute right-10 w-5 cursor-pointer" />
                            </div>
                        </div>
                        {/* End Main navigation dark blue */}
                    </div>
                </div>
                {/* MOBILE MENU */}
                <DisclosurePanel className="lg:hidden">
                    <div className="space-y-1 px-5 pb-5 pt-2">
                        {mobilenavigation.map((menu) => (
                            <ActiveLink
                                key={menu.menu}
                                to={menu.link}
                                className={({ isActive }) => isActive ? "barlow-bold border-2 border-transparent border-b-white text-white block px-3 py-3 text-base" : "barlow-regular text-white border-2 border-transparent block px-3 py-3 text-base"}
                            >
                                <DisclosureButton>{menu.menu}</DisclosureButton>   
                            </ActiveLink>
                        ))}
                        <div className="px-2 pt-3 pb-4">
                            <div className="flex flex-shrink-0 items-center">
                                <Input 
                                    name="search" 
                                    type="text" 
                                    className="barlow-regular text-base block rounded-3xl border border-gray-50 text-white px-4 py-2 bg-transparent placeholder:text-white w-full" 
                                    placeholder="Search"
                                    value={inputValue}
                                    onChange={handleSearchChange}
                                    onKeyDown={handleKeyPress}
                                />
                                <MagnifyingGlassIcon onClick={handleSearchSubmit} className="text-white absolute right-12 w-5 cursor-pointer" />
                            </div>
                        </div>
                    </div>                     
                </DisclosurePanel>
            </>
            )}
        </Disclosure>
    )
}
