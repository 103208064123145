import React from 'react';
import {PageBanner} from '../CommonComponents.js';
import { mmfmpConfig } from '../config.js';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'

const pagebanner = [
  {
    bannersrc: '/images/wherewework/mmfmp-wherewework-banner.jpg',
    header: 'Where We Work',
    copy: 'Learn about the focus areas affected by Component 2 of the MMFMP, their exact locations, and the interventions being done in accord.'
  }
]

function ProjectArea() {
  return (
    <section className="px-5 py-10">
        <div className="container mx-auto text-blue">
            <img src="/images/wherewework/mmfmp-wherewework-map.jpg" className="h-auto max-w-full mx-auto lg:px-20" alt="map"/>
            <div className="barlow-regular text-base grid lg:grid-cols-12 mt-10">
                <div className="lg:col-span-11">
                    <h1 className=" bakbak-one-regular uppercase text-4xl mb-5">MMFMP COMPONENT 2 PROJECT AREA</h1>
                    <p>In order to support and ensure the success of MMFMP Phase 1, a big part of Component 2 focuses on implementing SWM interventions in 270 barangays in the catchment drainage area of the pumping stations to be rehabilitated under Component 1.<br/><br/>To increase efficiency, the project areas were divided into two batches.</p>              
                </div>
            </div>
        </div>
        <div className="container mx-auto mt-10 lg:px-20">
            <div className="table-responsive">
                <table id="wherewework-table" className="w-full barlow-regular text-base text-blue">
                    <thead>
                        <tr>
                            <td width="20">BATCH</td>
                            <td width="40">Number of Project Drainage Areas </td>
                            <td width="40">No. of Barangays and City/Municipal LGUs </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>5 Pumping Stations for rehabilitation</td>
                            <td>85 barangays<br/>(in six LGUs: Manila, Makati, Pasay, Pasig, Pateros, Taguig) </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>37 Pumping stations for rehabilitation/ construction<br/>(note: 3 of the batch 1 PS are also included in batch 2) </td>
                            <td>185 barangays<br/>(in 13 LGUs: Manila, Makati, Pasay, Pasig, Pateros, Taguig, Caloocan, Malabon, Mandaluyong, Navotas, Paranaque, Quezon City, San Juan) </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
         <div className="container mx-auto mt-5">
            <div className="grid lg:grid-cols-12">
                <div className="lg:col-span-11 text-center barlow-regular text-base text-blue">
                    <p className="mb-3 barlow-bold text-lg">Breakdown of barangays per pumping station to be rehabilitated </p>
                    <a href={mmfmpConfig.cmsDomain + "wp-content/uploads/2024/09/MMFMP-Phase-1-breakdown-of-barangays-per-pumping-station.pdf"} target="_blank" rel="noopener noreferrer" className="barlow-regular rounded-lg border border-blue-900 px-4 py-2 inline-flex">
                    <DocumentArrowDownIcon className="block h-6 w-6 mr-2" aria-hidden="true" /> Download
                    </a>
                </div>
            </div>
        </div>
        <div className="container mx-auto mt-10">
            <div className="grid lg:grid-cols-12">
                <div className="lg:col-span-11 barlow-regular text-base text-blue">
                    <p className="mb-3">These specific project areas were chosen based on the following criteria:</p>
                    <ol className="list-decimal px-5">
                        <li>Upstream of the affected pumping station/drainage pond</li>
                        <li>Adjacent to the waterways, preferably contiguous with the other barangays</li>
                        <li>Within 100-meter distance from the centerline of the waterway</li>
                        <li>Considerably close to the pumping station (max of 1.5 km)</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
  )
}

export default function WhereWeWork() {
  return (
    <>
      <PageBanner pagebanner={pagebanner}/>
      <ProjectArea/>
    </>
  )
}
