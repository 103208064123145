import React from 'react';
import { Link } from "react-router-dom";
import SWMISGallery from '../Homepage/SWMISGallery.js'
import {PageBanner, PDFListing } from '../CommonComponents.js';

//GET ALL GRAPHQL RELATED
import { useQuery } from "react-query";
import { request } from "graphql-request";
import { mmfmpConfig } from '../config.js';
import { queries } from '../GraphQL/Queries.js';

//CALL THIS EVERY TIME NEED TO USE THE QUERY
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

const pagebanner = [
  {
    bannersrc: '/images/mmfmp-resources-banner.jpg',
    header: 'RESOURCES',
  }
]

function GetData(){
  const { data, isLoading, error } = useQuery("downloadData", () => {
      return request(mmfmpConfig.apiUrl, queries.downloadModule);
  });

  if (isLoading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;

  const downloadCategory = data.resourceCategories.nodes;

  // Define the desired order
  const order = [
      'Reports',
      'Information, Education, Campaign Materials',
      'Plans',
      'Studies',
      'Other Downloads',
  ];

  const sortedData = downloadCategory
      .slice() // Creates a shallow copy of the array to avoid modifying the original data
      .sort((a, b) => {
          return order.indexOf(a.name) - order.indexOf(b.name);
  });

  const Category = ({ category }) => (
    <section className="px-5 py-10">
      <div key={category.id} className="container mx-auto">
        <h1 className="text-3xl uppercase bakbak-one-regular" dangerouslySetInnerHTML={{__html: category.name}} />
        <p className="barlow-regular" dangerouslySetInnerHTML={{__html: category.description}} />      
        {/* Render children if available */}
        {
          category.children.edges.length > 0 
          ? 
          <>
          {
            category.children.edges.map((edge) => (
              <div key={edge.node.id} className="px-5 py-8">
                <div className="container mx-auto">
                  <h1 className="text-2xl bakbak-one-regular -mb-1" dangerouslySetInnerHTML={{__html: edge.node.name}} />
                  {/* <p className="barlow-regular" dangerouslySetInnerHTML={{__html: edge.node.description}} /> */}
                  <PDFListing pdffiles={edge.node.resources.nodes}/>
                  <Link to={edge.node.slug} className="text-xs sm:text-sm barlow-bold rounded-3xl border px-2 md:px-5 py-2">See more {edge.node.name}</Link>
                </div>
              </div>
            ))
          }
          </>
          : 
          /* If no children, render resources directly */
          <>
            <PDFListing pdffiles={category.resources.nodes} />
            <Link to={category.slug} className="text-xs sm:text-sm barlow-bold rounded-3xl border px-2 md:px-5 py-2">See more {category.name}</Link>
          </>
        }
      </div>
    </section>
  );

  return (
    <>
      {
        sortedData.map((category) => (
          <Category key={category.id} category={category} />
        ))
      }
    </>
  );
}

export function ResourcesBannerSection(){
    return <PageBanner pagebanner={pagebanner}/>
}

export default function ResourcesLandingCMS() {
  return (
    <>
      <ResourcesBannerSection />
      <QueryClientProvider client={queryClient}>
        <div id="resources-categories"><GetData /></div>            
        <SWMISGallery />
      </QueryClientProvider>
    </>
  )
}